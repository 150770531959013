/* eslint-disable no-restricted-globals */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import React, {
  useState, useRef, useMemo, useEffect,
} from 'react';
import ReactQuill from 'react-quill';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import { ToastContainer } from 'react-toastify';
import Input from '../../../components/forms/input';
import InputPrepend from '../../../components/forms/InputPrepend';
import InputPrepend2 from '../../../components/forms/InputPrepend2';
import ButtonAction from '../../../components/buttons/ButtonAction';
import Select2 from '../../../components/forms/Select2';
import ImageModal from '../../../components/modal/ImageModal';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import { updateInfaqById, getProgramInfaqById, AddStoryImage } from '../../../services/infaq/index';
import ConfirmationModal from '../../../components/modal/ConfirmModal';
import saveIcon from '../../../assets/images/icons/SaveIcon.svg';
import 'react-datepicker/dist/react-datepicker.css';

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};
const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function EditProgramPatronage(props) {
  const { id } = useParams();
  const formikRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [quillValue, setQuillValue] = useState('');
  const quillRef = useRef();
  const optionsUlangi = [
    { value: 'WEEKLY', label: 'Setiap Minggu' },
    { value: 'DAILY', label: 'Setiap Hari' },
  ];
  const optionsStatus = [
    { value: true, label: 'Aktif' },
    { value: false, label: 'Tidak Aktif' },
  ];
  const optionsFundraiser = [
    { value: 'Dzikra', label: 'Dzikra' },
    { value: 'Inovasi 360', label: 'Inovasi 360' },
    { value: 'rumah_zakat', label: 'Rumah Zakat' },
  ];
  const [programName, setProgramName] = useState('');
  const [media, setMedia] = useState();
  const [defaultMedia, setDefaultMedia] = useState(null);
  const [defaultThumnail, setDefaulThumbnail] = useState(null);
  const [mediaName, setMediaName] = useState('Upload File Poster');
  const [donationTarget, setDonationTarget] = useState('');
  const [donationTargetDisplay, setDonationTargetDisplay] = useState('');
  const [duration, setDuration] = useState('');
  const [status, setStatus] = useState(null);
  const [fundraiser, setFundraiser] = useState('');
  const [thumbnail, setThumbnail] = useState();
  const [nameThumbnail, setNameThumbnail] = useState('Upload File Gambar Cerita Program');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [defaultEndDate, setDefaultEndDate] = useState(null);
  const [stopTimeDisplay, setStopTimeDisplay] = useState('');
  const [repeat, setRepeat] = useState('');
  const [storyDescription, setStoryDescription] = useState('');
  const [swich, setSwich] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [isInvalidClassName, setIsInvalidClassName] = useState('');
  const [isDblock, setIsDblock] = useState('d-none');
  const [isEditedInfaq, setIsEditedInfaq] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalCloseConfirmation, setShowModalCloseConfirmation] = useState(false);
  const getDate = moment().format('YYYY-MM-DD');
  const [showImageMedia, setShowImageMedia] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [editMedia, setEditMedia] = useState(false);
  const [editThumbnail, setEditThumbnail] = useState(false);

  const initialValues = {
    programName: programName ?? '',
    media: media ?? '',
    donationTarget: donationTarget ?? '',
    duration: duration ?? '',
    status: status === null ? false : status,
    fundraiser: fundraiser ?? '',
    thumbnail: thumbnail ?? null,
    startTime: startTime ?? null,
    endTime: endTime ?? null,
    repeat: repeat ?? '',
  };

  const validation = Yup.object().shape({
    programName: Yup.string()
      .required('Nama Patronage Wajib Diisi')
      .max(100, 'Nama Patronage Terlalu Panjang')
      .nullable(),
    status: Yup.boolean().required('Status Wajib Diisi').nullable(),
    fundraiser: Yup.string().required('Jenis Penggalang Dana Wajib Diisi').nullable(),
    media: Yup.mixed().when('editMedia', {
      is: true,
      then: (schema) => schema
        .required('Media Wajib Diisi')
        .test(
          'fileSize',
          'Gambar Maksimal 1MB',
          (value) => !value || value.size <= 1000000,
        )
        .test(
          'fileFormat',
          'Tipe file tidak didukung',
          (value) => !value || ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(value.type),
        ),
    }),
  });

  const imageHandler = () => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      try {
        const formData = new FormData();
        formData.append('storyDescriptionImg', file);
        const response = await AddStoryImage(formData);
        const result = await response.text();
        const link = result;
        editor.insertEmbed(editor.getSelection(), 'image', link);
      } catch (err) {
        console.log('upload err:', err);
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  }), []);

  const handleSwich = (e) => {
    setSwich(e.target.checked);
  };

  const handleClose = () => setShowModal(false);
  const handleCloseModalConfirmation = () => setShowModalCloseConfirmation(false);
  const checkForm = (oldvalues, currentValues) => {
    if (id) {
      if (oldvalues.programName !== currentValues.programName
      || oldvalues.media !== currentValues.media
      || oldvalues.donationTarget !== currentValues.donationTarget
      || oldvalues.status !== currentValues.status
      || oldvalues.fundraiser !== currentValues.fundraiser
      || oldvalues.thumbnail !== currentValues.thumbnail
      || oldvalues.startTime !== currentValues.startTime
      || oldvalues.endTime !== currentValues.endTime
      || oldvalues.repeat !== currentValues.repeat) {
        setIsEditedInfaq(true);
      } else {
        setIsEditedInfaq(false);
      }
    }
  };
  const isFormEditedProgress = () => {
    if (id && isEditedInfaq) {
      setShowModalCloseConfirmation(true);
    } else {
      navigate('/patronage/program');
    }
  };
  const handleShowMediaInput = (event) => {
    setShowImageMedia(true);
  };

  const handleCloseMediaInput = () => {
    setShowImageMedia(false);
  };
  const handleShowThumbnailInput = () => {
    setShowThumbnail(true);
  };

  const handleCloseThumnailInput = () => {
    setShowThumbnail(false);
  };
  useEffect(() => {
    const handleProgramInfaq = async () => {
      const response = await getProgramInfaqById(id);
      setDefaultMedia(response.media);
      setDefaulThumbnail(response.storyThumbnail);
      setProgramName(response.programName);
      setDonationTarget(response.donationTarget);
      setMedia(response.media);
      if (response.media !== null) {
        const mediaFilename = response.media.split('/').pop();
        setMediaName(mediaFilename);
      }
      setStatus(response.status);
      setFundraiser(response.fundraiser);
      setStartTime(response.startTime);
      setEndTime(response.endTime);
      setRepeat(response.repeat);
      setStoryDescription(response.storyDescription);
      setThumbnail(response.storyThumbnail);
      if (response.storyThumbnail !== null) {
        const storyThumbnailFilename = response.storyThumbnail.split('/').pop();
        setNameThumbnail(storyThumbnailFilename);
      }
      setDefaultStartDate(response.startDate.replaceAll('-', '/'));
      setDefaultEndDate(response.endDate.replaceAll('-', '/'));
      const moneyFormatter = new Intl.NumberFormat();
      const donationFormat = moneyFormatter.format(response.donationTarget);
      setDonationTargetDisplay(donationFormat.replaceAll(',', '.'));
      setDonationTarget(response.donationTarget);
      const editor = quillRef.current.getEditor();
      editor.clipboard.dangerouslyPasteHTML(response.storyDescription);
      const start = moment(response.startDate, 'YYYY-MM-DD');
      const end = moment(response.endDate, 'YYYY-MM-DD');
      const day = moment.duration(end.diff(start)).asDays();
      setDuration(day);
    };
    if (id) {
      handleProgramInfaq();
    }
  }, []);

  return (
    <div className="content-container-side">
      <ImageModal show={showImageMedia} handleClose={handleCloseMediaInput} srcMedia={defaultMedia} />
      <ImageModal show={showThumbnail} handleClose={handleCloseThumnailInput} srcMedia={defaultThumnail} />
      <div className="d-flex">
        <p className="content-title">
          Patronage
        </p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/patronage/program"
            onClick={() => {
            }}
            style={styleActiveBreadcrumb}
          >
            Daftar Program
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Edit Program
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <span
            className="material-icons clickable"
            style={{ marginTop: '-12px' }}
            onClick={() => {
              isFormEditedProgress();
            }}
            onKeyPress={() => navigate('/patronage/program')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Edit Program
          </p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validation}
          enableReinitialize
          onSubmit={(values, errors, touched) => {
            setShowModal(true);
          }}
        >
          {(props) => (
            <Form>
              {checkForm(initialValues, props.values)}
              <ConfirmationModal
                show={showModal}
                message="Apakah Anda yakin data yang dimasukkan sudah sesuai?"
                onHide={handleClose}
                confirmTitle="Ya, lanjutkan"
                cancelTitle="Periksa Kembali"
                toggle={handleClose}
                inlineIcon={saveIcon}
                confirm={() => {
                  setShowModal(false);
                  const end = new Date(endDate);
                  const start = new Date(startDate);
                  const rangeStart = start.toLocaleDateString().split('/');
                  const rangeEnd = end.toLocaleDateString().split('/');
                  const formatedStartDate = (`${rangeStart[2]}/${rangeStart[0]}/${rangeStart[1]}`);
                  const formatedEndDate = (`${rangeEnd[2]}/${rangeEnd[0]}/${rangeEnd[1]}`);
                  const formData = new FormData();
                  formData.append('programName', props.values.programName);
                  formData.append('duration', props.values.duration);
                  formData.append('status', props.values.status);
                  formData.append('fundraiser', props.values.fundraiser);
                  formData.append('donationTarget', props.values.donationTarget);
                  formData.append('thumbnail', props.values.thumbnail);
                  formData.append('startTime', props.values.startTime);
                  formData.append('endTime', props.values.endTime);
                  formData.append('repeat', props.values.repeat);
                  formData.append('startDate', startDate === null ? defaultStartDate : formatedStartDate);
                  formData.append('endDate', endDate === null ? defaultEndDate : formatedEndDate);
                  formData.append('media', props.values.media);
                  formData.append('donationTarget', props.values.donationTarget);
                  formData.append('storyDescription', quillValue);
                  formData.append('isPatronage', true);
                  updateInfaqById(id, formData).then((response) => {
                    if (response.status === 200) {
                      localStorage.setItem('isEditedInfaq', true);
                      setTimeout(() => {
                        navigate('/patronage/program');
                      }, 100);
                    }
                  });
                }}
              />
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Input
                    title="Nama Donasi"
                    name="programName"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('programName')}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <Input
                    title="Poster"
                    name="media"
                    isShowMedia
                    filename={mediaName}
                    onShowMedia={handleShowMediaInput}
                    type="file"
                    errors={props?.errors}
                    touched={props?.touched}
                    onChange={(e) => {
                      props?.setFieldValue('media', e.target.files[0]);
                      setMediaName(e.target.files[0].name);
                      setEditMedia(true);
                    }}
                    accept="image/png, image/jpeg"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Tanggal</label>
                    <DatePicker
                      selectsRange
                      className={`form-input form-control placeholder-black ${isInvalidClassName}`}
                      startDate={startDate}
                      placeholderText={defaultStartDate ? `${defaultStartDate} - ${defaultEndDate}` : ''}
                      endDate={endDate}
                      minDate={new Date(getDate)}
                      onChange={(update) => {
                        setDateRange(update);
                        const start = moment(update[0], 'YYYY-MM-DD');
                        const end = moment(update[1], 'YYYY-MM-DD');
                        const day = moment.duration(end.diff(start)).asDays();
                        setDuration(day);
                      }}
                    />
                    <div className={`invalid-feedback d-block ${isDblock}`}>
                      <p className="ms-1">Field Tanggal Harus Diisi</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Select2
                    title="Status"
                    key={status}
                    defaultValue={optionsStatus.find(
                      ({ value }) => value === status,
                    )}
                    name="status"
                    placeholder="Pilih Data Status"
                    options={optionsStatus}
                    onChangeEvent={(selectedOption) => {
                      props.setFieldValue('status', selectedOption.value);
                    }}
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('status')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Select2
                    title="Penggalang Dana"
                    key={fundraiser}
                    defaultValue={optionsFundraiser.find(
                      ({ value }) => value === fundraiser,
                    )}
                    name="fundraiser"
                    placeholder="Pilih Data Status"
                    options={optionsFundraiser}
                    onChangeEvent={(selectedOption) => {
                      props.setFieldValue('fundraiser', selectedOption.value);
                    }}
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('fundraiser')}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mt-5">
                <div className="ms-auto">
                  <ButtonAction
                    cancelTitle="Batal"
                    confirmTitle="Simpan"
                    toggle={() => {
                      isFormEditedProgress();
                      // checkForm(formikRef.current.values);
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <ConfirmationModal
          show={modalCloseConfirmation}
          message="Apakah anda yakin ingin membatalkan proses edit program infaq?"
          onHide={handleClose}
          confirmTitle="Yakin"
          cancelTitle="Batal"
          toggle={handleCloseModalConfirmation}
          inlineIcon={saveIcon}
          confirm={() => {
            navigate('/patronage/program');
          }}
        />
        <ToastContainer />
      </Card>
    </div>
  );
}

export default EditProgramPatronage;
