/* eslint-disable default-param-last */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { Card } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import AddIcon from '@mui/icons-material/Add';
import FilterIcon from '@mui/icons-material/FilterList';
import { MenuItem } from '@mui/material';
import SortIcon from '@mui/icons-material/UnfoldMore';
import Profile from '../../../components/profile/profile';
import Search from '../../../components/filter/Search';
import Table from '../../../components/table/index';
import TableStatus from '../../../components/table/status';
import ButtonDetail from '../../../components/table/button-detail';
import { toastSuccess } from '../../../components/toast';
import FilterModal from '../../../components/modal/FilterModal';
import { getInfaqProgram } from '../../../services/infaq';
import '../../../assets/styles/infaq.scss';

// import TableStatus from '../../../components/table/status';

function Index() {
  const navigate = useNavigate();
  const styledButton = {
    textTransform: 'capitalize',
    color: '#4F5867',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: 'none',
  };
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(2);
  const [totalData, setTotalData] = useState(0);
  const [programData, setProgramData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [sort, setSort] = useState('updatedAt.desc');
  const [paramFilter, setParamFilter] = useState({});
  const [isOnFilter, setIsOnFilter] = useState(false);

  useEffect(() => {
    const isAdded = localStorage.getItem('isAdded');
    if (isAdded) {
      toastSuccess('Data Berhasil Ditambah');
      localStorage.removeItem('isAdded');
    }
    const isEditedInfaq = localStorage.getItem('isEditedInfaq');
    if (isEditedInfaq) {
      toastSuccess('Data Berhasil Diedit');
      localStorage.removeItem('isEditedInfaq');
    }
    const handleTable = async () => {
      const dataProgramInfaq = await getInfaqProgram({
        deleted: false,
        draw: 0,
        id: '',
        variant: '',
        length: 10,
        page,
        isPatronage: true,
        rangeEnd: paramFilter?.endDate ?? '',
        rangeStart: paramFilter?.startDate ?? '',
        searchText: searchKeyword,
        sort: [
          sort,
        ],
        start: 1,
        status: paramFilter?.selectValue ?? '',
      });
      setTotalData(dataProgramInfaq.recordTotal);
      setProgramData(dataProgramInfaq.data);
      setMaxPage(Math.ceil(dataProgramInfaq.recordTotal / 10));
    };
    handleTable();
  }, [page, sort, searchKeyword, paramFilter]);
  const handleSort = () => {
    if (sort === 'updatedAt.desc' || sort === 'programName.desc') {
      setSort('programName.asc');
    } else {
      setSort('programName.desc');
    }
  };

  const handleFilter = (selectedOption, secondSelectedOption, startDate, endDate) => {
    let filterStatus;
    let filterStartDate;
    let filterEndDate;
    if (selectedOption && selectedOption.value !== undefined) {
      filterStatus = selectedOption.value;
    }
    const end = new Date(endDate);
    const start = new Date(startDate);

    if (startDate !== null && endDate !== null) {
      const rangeStart = start.toLocaleDateString().split('/');
      const rangeEnd = end.toLocaleDateString().split('/');
      filterStartDate = `${rangeStart[2]}/${rangeStart[0]}/${rangeStart[1]}`;
      filterEndDate = `${rangeEnd[2]}/${rangeEnd[0]}/${rangeEnd[1]}`;
    }

    setParamFilter({
      selectValue: filterStatus,
      startDate: filterStartDate,
      endDate: filterEndDate,
    });
    setShowModalFilter(false);
    setIsOnFilter(true);

    if (!filterStatus && !filterStartDate && !filterEndDate) {
      setIsOnFilter(false);
    }
  };

  const handleFilterReset = () => {
    setParamFilter({
      selectValue: '',
      startDate: null,
      endDate: null,
    });
    setShowModalFilter(false);
    setIsOnFilter(false);
  };

  const columns = [
    {
      Header: 'No',
      accessor: 'no',
      Cell: ({ row }) => <div>{row.index + 1}</div>,
    },
    {
      Header: 'Nama Patronage',
      Cell: ({ row }) => {
        const { original } = row;
        const { programName } = original;
        return programName;
      },
    },
    {
      Header: 'Tanggal Aktivasi',
      Cell: ({ row }) => {
        const monthNames = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
        const { original } = row;
        const { startDate } = original;
        const splitedDate = startDate.split('-');
        return `${splitedDate[2]} ${monthNames[splitedDate[1] - 1]} ${splitedDate[0]}`;
      },
    },
    {
      Header: 'Durasi',
      Cell: ({ row }) => {
        const { original } = row;
        const { startDate, endDate } = original;
        const start = moment(startDate, 'YYYY-MM-DD');
        const end = moment(endDate, 'YYYY-MM-DD');
        const day = moment.duration(end.diff(start)).asDays();
        return `${day} Hari`;
      },
    },
    // {
    //   Header: 'Target Donasi',
    //   Cell: ({ row }) => {
    //     const { original } = row;
    //     const { donationTarget } = original;
    //     const moneyFormatter = new Intl.NumberFormat();
    //     return `Rp ${moneyFormatter.format(donationTarget).replaceAll(',', '.')}`;
    //   },
    // },
    {
      Header: 'Status',
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;
        return <TableStatus status={status ? 'Aktif' : 'Tidak Aktif'} color={status ? 'primary' : 'danger'} />;
      },
    },
    {
      Header: ' ',
      Cell: ({ row }) => {
        const { original } = row;
        const { id } = original;
        return (
          <ButtonDetail>
            <MenuItem onClick={() => {
              navigate(`/patronage/program/detail-program/${id}`);
            }}
            >
              Detail

            </MenuItem>
            <MenuItem onClick={() => {
              navigate(`/patronage/program/edit-program/${id}`);
            }}
            >
              Edit

            </MenuItem>
          </ButtonDetail>
        );
      },
    },
  ];

  const optionsFilter = [
    { value: '', label: 'Semua Status' },
    { value: 'active', label: 'Aktif' },
    { value: 'inactive', label: 'Tidak Aktif' },
  ];

  return (
    <div className="content-container-side">
      <div className="d-flex flex-wrap">
        <p className="content-title mt-3">Patronage</p>
        <div className="ms-auto">
          <div className="d-flex flex-wrap justify-content-end align-items-center">
            <Profile label="Ridwan Fajar Widodo" />
          </div>
        </div>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <p className="card-datatable-title">Daftar Program</p>
          <div className="ms-auto">
            <div className="d-flex flex-wrap justify-content-end align-items-center">
              <Search
                name="search"
                type="text"
                placeholder="Masukkan kata kunci"
                style={{ marginRight: 10 }}
                mt="3 mt-sm-0"
                value={searchKeyword}
                onChange={(event) => setSearchKeyword(event.target.value)}
              />
              <div className="mt-3 mt-sm-0 me-2">
                {isOnFilter ? (
                  <Badge variant="dot" color="warning">
                    <Button
                      onClick={() => setShowModalFilter(true)}
                      variant="contained"
                      aria-label="filter"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      size="medium"
                      sx={{
                        border: '1px solid #2EC4B6 !important',
                      }}
                      style={styledButton}
                      endIcon={<FilterIcon />}
                    >
                      Filter
                    </Button>
                  </Badge>
                ) : (
                  <Button
                    onClick={() => setShowModalFilter(true)}
                    variant="contained"
                    aria-label="filter"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    size="medium"
                    style={styledButton}
                    endIcon={<FilterIcon />}
                  >
                    Filter
                  </Button>
                )}
              </div>
              <Button
                variant="contained"
                aria-label="filter"
                aria-controls="long-menu"
                aria-haspopup="true"
                style={styledButton}
                size="medium"
                endIcon={<SortIcon />}
                onClick={handleSort}
              >
                Sort
              </Button>
              <Button
                onClick={() => {
                  navigate('/patronage/program/add');
                }}
                variant="contained"
                aria-label="add"
                size="medium"
                style={{
                  textAlign: 'center',
                  padding: '0.5em 1em',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.5rem',
                  background: '#2ec4b6',
                }}
                className="ms-2 mt-3 mt-sm-0"
              >
                <AddIcon
                  style={{
                    fontSize: 16,
                    marginRight: 5,
                  }}
                />
                Tambah
              </Button>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          data={programData}
          pagination={{
            page,
            totalData,
            maxPage,
            toggle: setPage,
          }}
        />
      </Card>
      <FilterModal
        show={showModalFilter}
        onHide={() => setShowModalFilter(false)}
        select
        titleSelect="Status"
        placeholderSelect="Pilih Status"
        confirmTitle="Terapkan"
        cancelTitle="Batal"
        withDateRange
        paramFilter={paramFilter}
        selectOptions={optionsFilter}
        handleFilter={handleFilter}
        handleReset={handleFilterReset}
        toggle={() => setShowModalFilter(false)}
        confirm={() => {
        }}
      />
    </div>
  );
}

export default Index;
