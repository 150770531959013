/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable no-trailing-spaces */
import { React, useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { delValues } from '../../stores/actions/mission/formMission';
import ConfirmationModal from '../modal/ConfirmModal';
import Logo from '../../assets/images/logo/logo.svg';
import { toastSuccess } from '../toast';
import '../../assets/styles/sidebar.scss';
import UserIcon from '../../assets/images/icons/UserIcon.svg';
import UserInactiveIcon from '../../assets/images/icons/UserInactiveIcon.svg';
import DashboardIcon from '../../assets/images/icons/DashboardIcon.svg';
import DashboardInactive from '../../assets/images/icons/DashboardInactive.svg';
import MisiIcon from '../../assets/images/icons/MisiIcon.svg';
import LeftChevronIcon from '../../assets/images/icons/ic_round-arrow-back.svg';
import DownChevronIcon from '../../assets/images/icons/ic_round-arrow-down.svg';
import MisiInactiveIcon from '../../assets/images/icons/MisiInactiveIcon.svg';
import NotifIcon from '../../assets/images/icons/NotifIcon.svg';
import NotifInactiveIcon from '../../assets/images/icons/NotifInactiveIcon.svg';
import OffIcon from '../../assets/images/icons/OffIcon.svg';
import FavoritIcon from '../../assets/images/icons/FavoritIcon.svg';
import FavoritInactiveIcon from '../../assets/images/icons/FavoritInactiveIcon.svg';
import infaq from '../../assets/images/icons/Infaq.svg';
import infaqInActive from '../../assets/images/icons/InfaqInactive.svg';
import DoaIcon from '../../assets/images/icons/DoaIcon.svg';
import DoaInactiveIcon from '../../assets/images/icons/DoaInactiveIcon.svg';
import DzikirIcon from '../../assets/images/icons/DzikirIcon.svg';
import saveIcon from '../../assets/images/icons/SaveIcon.svg';
import DzikirInactiveIcon from '../../assets/images/icons/DzikirInactiveIcon.svg';
import { setEditedVersionValue, setAddeddVersionValue } from '../../stores/actions/whatnew/form';
import { setEditedNotificationValue, setAddeddNotificationValue } from '../../stores/actions/notification/formNotification';
import { setEditedDoaValue, setAddedDoaValue } from '../../stores/actions/doa/formDoa';
import { setEditedDzikirValue, setAddedDzikirValue } from '../../stores/actions/dzikir/formDzikir';

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pathname, setPathname] = useState('/');
  const [modalMessage, setModalMessage] = useState('');
  const [showModalClose, setShowModalClose] = useState(false);
  const [pathDestination, setPathDestination] = useState('/');
  const [isDropDownOpen, setIsDropDownOpen] = useState('d-none');
  const [isDropDownActive, setIsDropDownActive] = useState('');
  const [collapsedSb, setCollapsedSb] = useState(null);
  const location = useLocation();
  const missionFormvalues = useSelector((state) => state.missionReducer.values);
  const isFormMissionOnEdit = useSelector(
    (state) => state.missionReducer.isEdited,
  );
  const isFormNotificationOnEdit = useSelector(
    (state) => state.notificationFormReducer.isEdited,
  );
  const isFormNotificationOnAdded = useSelector(
    (state) => state.notificationFormReducer.isAdded,
  );
  const typeOfMission = useSelector((state) => state.missionReducer.type);
  const actionOfMission = useSelector((state) => state.missionReducer.action);

  const isFormWhatNewEdited = useSelector((state) => state.whatNewFormReducer.isEdited);
  const isFormWhatNewAdded = useSelector((state) => state.whatNewFormReducer.isAdded);
  
  const isFormDoaAdded = useSelector((state) => state.doaReducer.isAdded);
  const isFormDoaEdited = useSelector((state) => state.doaReducer.isEdited);

  const isFormDzikirAdded = useSelector((state) => state?.dzikirReducer?.isAdded);
  const isFormDzikirEdited = useSelector((state) => state?.dzikirReducer?.isEdited);

  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  const logout = () => {
    localStorage.removeItem('user_dzikra');
    localStorage.removeItem('persist:root');
    window.location.href = '/';
    toastSuccess('Logout Berhasil');
  };

  const toogleCloseModal = () => {
    setShowModalClose(false);
  };

  const checkIsNotificationForminProgress = (path) => {
    if (isFormNotificationOnEdit) {
      setShowModalClose(true);
      setModalMessage(
        'Apakah anda yakin ingin membatalkan proses edit notifikasi?',
      );
    } else if (isFormNotificationOnAdded) {
      setShowModalClose(true);
      setModalMessage(
        'Apakah anda yakin ingin membatalkan proses tambah notifikasi?',
      );
    } else {
      navigate(path);
      setPathname(path);
    }
  };

  const checkIsMissionFormInProgress = (path) => {
    if (actionOfMission === 'add') {
      if (
        missionFormvalues.missionName === ''
        && missionFormvalues.description === ''
        && missionFormvalues.activationDate === ''
        && missionFormvalues.totalDay === ''
        && missionFormvalues.banner === ''
        && missionFormvalues.color === ''
        && missionFormvalues.badgeImage === ''
        && missionFormvalues.badgeName === ''
        && missionFormvalues.badgeDescription === ''
      ) {
        navigate(path);
        setPathname(path);
      } else if (missionFormvalues.length === 0) {
        navigate(path);
        setPathname(path);
      } else {
        setShowModalClose(true);
        setModalMessage(
          `Apakah anda yakin ingin membatalkan proses ${
            actionOfMission === 'add' ? 'tambah' : 'edit'
          } ${typeOfMission} mission?`,
        );
        setPathDestination(path);
      }
    } else if (actionOfMission === 'edit') {
      if (isFormMissionOnEdit) {
        setShowModalClose(true);
        setModalMessage(
          `Apakah anda yakin ingin membatalkan proses ${
            actionOfMission === 'add' ? 'tambah' : 'edit'
          } ${typeOfMission} mission?`,
        );
        setPathDestination(path);
      } else {
        navigate(path);
        setPathname(path);
      }
    } else {
      navigate(path);
      setPathname(path);
    }
  };

  const checkIsWhatNewFormInProgress = (path) => {
    if (isFormWhatNewEdited) {
      setShowModalClose(true);
      setModalMessage(
        'Apakah anda yakin ingin membatalkan proses edit what new?',
      );
    } else if (isFormWhatNewAdded) {
      setShowModalClose(true);
      setModalMessage(
        'Apakah anda yakin ingin membatalkan proses tambah whats new?',
      );
    } else {
      navigate(path);
      setPathname(path);
    }
  };

  const checkIsDoaForminProgress = (path) => {
    if (isFormDoaEdited) {
      setShowModalClose(true);
      setModalMessage(
        'Apakah anda yakin ingin membatalkan proses edit doa?',
      );
    } else if (isFormDoaAdded) {
      setShowModalClose(true);
      setModalMessage(
        'Apakah anda yakin ingin membatalkan proses tambah doa?',
      );
    } else {
      navigate(path);
      setPathname(path);
    }
  };

  const checkIsDzikirForminProgress = (path) => {
    if (isFormDzikirEdited) {
      setShowModalClose(true);
      setModalMessage(
        'Apakah anda yakin ingin membatalkan proses edit dzikir?',
      );
    } else if (isFormDzikirAdded) {
      setShowModalClose(true);
      setModalMessage(
        'Apakah anda yakin ingin membatalkan proses tambah dzikir?',
      );
    } else {
      navigate(path);
      setPathname(path);
    }
  };

  const checkCloseConfirmation = (path) => {
    const pathDeparture = location.pathname.split('/')[1];
    setPathDestination(path);
    if (pathDeparture === 'notification') {
      checkIsNotificationForminProgress(path);
    } else if (pathDeparture === 'mission') {
      checkIsMissionFormInProgress(path);
    } else if (pathDeparture === 'whatsnew') {
      checkIsWhatNewFormInProgress(path);
    } else if (pathDeparture === 'doa') {
      checkIsDoaForminProgress(path);
    } else if (pathDeparture === 'dzikir') {
      checkIsDzikirForminProgress(path);
    } else {
      navigate(path);
      setPathname(path);
    }
  };

  return (
    <div
      className="d-flex flex-column p-3 pb-0 bg-white sidebar-container h-50 sticky-top"
      style={{ minHeight: '100vh' }}
    >
      <div className="px-5 mb-3">
        <img src={Logo} alt="logo" className="justify-content-center" />
      </div>
      <div className="mb-2">
        <NavLink
          to="/dashboard"
          className="d-flex align-items-center"
          onClick={(event) => {
            event.preventDefault();
            checkCloseConfirmation('/dashboard');
            setIsDropDownOpen('d-none');
            setCollapsedSb('dashboard');
          }}
        >
          {pathname === '/dashboard' ? (
            <img src={DashboardIcon} alt="Dashboard Icon" />
          ) : (
            <img src={DashboardInactive} alt="Dashboard Icon" />
          )}
          <span className="px-3">Dashboard</span>
        </NavLink>
      </div>
      <div className="mb-2">
        <NavLink
          to="/dzikir"
          className="d-flex align-items-center"
          onClick={(event) => {
            event.preventDefault();
            checkCloseConfirmation('/dzikir');
            setIsDropDownOpen('d-none');
            setCollapsedSb('dzikir');
          }}
        >
          {pathname.indexOf('/dzikir') > -1 ? (
            <img src={DzikirIcon} alt="Dzikir Icon" />
          ) : (
            <img src={DzikirInactiveIcon} alt="Dzikir Icon" />
          )}
          <span className="px-3">Dzikir</span>
        </NavLink>
      </div>
      <div className="mb-2">
        <NavLink
          to="/doa"
          className="d-flex align-items-center"
          onClick={(event) => {
            event.preventDefault();
            checkCloseConfirmation('/doa');
            setIsDropDownOpen('d-none');
            setCollapsedSb('doa');
          }}
        >
          {pathname.indexOf('/doa') > -1 ? (
            <img src={DoaIcon} alt="Doa Icon" />
          ) : (
            <img src={DoaInactiveIcon} alt="Doa Icon" />
          )}
          <span className="px-3">Doa</span>
        </NavLink>
      </div>
      <div className="mb-2">
        <NavLink
          to="/mission"
          className="d-flex align-items-center"
          onClick={(event) => {
            event.preventDefault();
            checkCloseConfirmation('/mission');
            setIsDropDownOpen('d-none');
            setCollapsedSb('mission');
          }}
        >
          {pathname.indexOf('/mission') > -1 ? (
            <img src={MisiIcon} alt="Misi Icon" />
          ) : (
            <img src={MisiInactiveIcon} alt="Misi Icon" />
          )}
          <span className="px-3">Misi</span>
        </NavLink>
      </div>
      <div className="mb-2">
        <NavLink
          to="/whatsnew"
          className="d-flex align-items-center"
          onClick={(event) => {
            event.preventDefault();
            checkCloseConfirmation('/whatsnew');
            setIsDropDownOpen('d-none');
            setCollapsedSb('whatsnew');
          }}
        >
          {pathname === '/whatsnew' ? (
            <img src={FavoritIcon} alt="whatsnew Icon" />
          ) : (
            <img src={FavoritInactiveIcon} alt="whatsnew Icon" />
          )}
          <span className="px-3">Whats New Update</span>
        </NavLink>
      </div>
      <div className="mb-2">
        <NavLink
          to="/user"
          className="d-flex align-items-center"
          onClick={(event) => {
            event.preventDefault();
            checkCloseConfirmation('/user');
            setIsDropDownOpen('d-none');
            setCollapsedSb('user');
          }}
        >
          {pathname === '/user' ? (
            <img src={UserIcon} alt="User Icon" />
          ) : (
            <img src={UserInactiveIcon} alt="User Icon" />
          )}
          <span className="px-3">Pengguna</span>
        </NavLink>
      </div>
      <div className="mb-2">
        <NavLink
          to="/notification"
          className="d-flex align-items-center"
          onClick={(event) => {
            event.preventDefault();
            checkCloseConfirmation('/notification');
            setIsDropDownOpen('d-none');
            setCollapsedSb('notification');
          }}
        >
          {pathname === '/notification' ? (
            <img src={NotifInactiveIcon} alt="Notif Icon" />
          ) : (
            <img src={NotifIcon} alt="Notif Icon" />
          )}
          <span className="px-3">Notifikasi</span>
        </NavLink>
      </div>
      <div className="infaq">
        <div className="mb-2">
          <NavLink
            to="/infaq"
            className="d-flex align-items-center"
            onClick={(event) => {
              event.preventDefault();
              setCollapsedSb('infaq');
              checkCloseConfirmation('/infaq');
            }}
          >
            <img src={pathname.includes('/infaq') ? infaq : infaqInActive} alt="Notif Icon" />
            <span className="px-3">Infaq</span>
            {pathname.includes('/infaq') && collapsedSb === 'infaq' ? (
              <img className="ms-auto" src={DownChevronIcon} alt="close sidebar icon" />
            ) : (
              <img className="ms-auto" src={LeftChevronIcon} alt="open sidebar Icon" />
            )}
          </NavLink>
        </div>
        <div className={`${collapsedSb === 'infaq' ? 'd-block' : 'd-none'} ms-2 mb-1`}>
          <NavLink
            to="/infaq/program"
            className={`${pathname === '/infaq' ? 'active' : ''} d-flex align-items-center bg-none `}
            onClick={(event) => {
              event.preventDefault();
              checkCloseConfirmation('/infaq/program');
            }}
          >
            <span className="px-3">Daftar Program</span>
          </NavLink>
        </div>
        <div className={`${collapsedSb === 'infaq' ? 'd-block' : 'd-none'} ms-2 mb-1`}>
          <NavLink
            to="/infaq/penerimaan"
            className="d-flex align-items-center bg-none"
            onClick={(event) => {
              event.preventDefault();
              checkCloseConfirmation('/infaq/penerimaan');
            }}
          >
            <span className="px-3">Penerimaan</span>
          </NavLink>
        </div>
        <div className={`${collapsedSb === 'infaq' ? 'd-block' : 'd-none'} ms-2 mb-1`}>
          <NavLink
            to="/infaq/penggunaan"
            className="d-flex align-items-center bg-none"
            onClick={(event) => {
              event.preventDefault();
              checkCloseConfirmation('/infaq/penggunaan');
            }}
          >
            <span className="px-3">Penggunaan</span>
          </NavLink>
        </div>
      </div>
      <div className="patronage ">
        <div className="mb-2">
          <NavLink
            to="/patronage"
            className="d-flex align-items-center"
            onClick={(event) => {
              event.preventDefault();
              setCollapsedSb('patronage');
              checkCloseConfirmation('/patronage');
            }}
          >
            {pathname.includes('/patronage') ? (
              <img src={infaq} alt="Notif Icon" />
            ) : (
              <img src={infaqInActive} alt="Notif Icon" />
            )}
            <span className="px-3">Patronage</span>
            {pathname.includes('/patronage') && isDropDownOpen === 'd-block' ? (
              <img className="ms-auto" src={DownChevronIcon} alt="close sidebar icon" />
            ) : (
              <img className="ms-auto" src={LeftChevronIcon} alt="open sidebar Icon" />
            )}
          </NavLink>
        </div>
        <div className={`${collapsedSb === 'patronage' ? 'd-block' : 'd-none'} ms-2 mb-1`}>
          <NavLink
            to="/patronage/program"
            className={`${pathname === '/patronage' ? 'active' : ''} d-flex align-items-center bg-none `}
            onClick={(event) => {
              event.preventDefault();
              checkCloseConfirmation('/patronage/program');
            }}
          >
            <span className="px-3">Daftar Patronage</span>
          </NavLink>
        </div>
        <div className={`${collapsedSb === 'patronage' ? 'd-block' : 'd-none'} ms-2 mb-1`}>
          <NavLink
            to="/patronage/penerimaan"
            className="d-flex align-items-center bg-none"
            onClick={(event) => {
              event.preventDefault();
              checkCloseConfirmation('/patronage/penerimaan');
            }}
          >
            <span className="px-3">Penerimaan</span>
          </NavLink>
        </div>
      </div>
      <div
        className="mt-auto"
        style={{ 
          // position: 'fixed', 
          bottom: 0,
          background: '#fff',
          minWidth: '220px',
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '17px',
            color: 'rgba(79, 88, 103, 0.7)',
            textDecoration: 'none',
            padding: '8px 15px',
          }}
          onClick={() => logout()}
          onKeyPress={() => logout()}
          role="button"
          tabIndex={0}
        >
          <img src={OffIcon} alt="Logout Icon" />
          <span className="px-3">Logout</span>
        </div>
      </div>
      <ConfirmationModal
        show={showModalClose}
        toggle={toogleCloseModal}
        message={modalMessage}
        confirmTitle="Yakin"
        cancelTitle="Batal"
        inlineIcon={saveIcon}
        confirm={() => {
          setPathname(pathDestination);
          navigate(pathDestination);
          setShowModalClose(false);
          dispatch(setEditedNotificationValue(false));
          dispatch(setEditedVersionValue(false));
          dispatch(setAddeddVersionValue(false));
          dispatch(setAddeddNotificationValue(false));
          dispatch(setAddedDoaValue(false));
          dispatch(setEditedDoaValue(false));
          dispatch(setAddedDzikirValue(false));
          dispatch(setEditedDzikirValue(false));
          dispatch(delValues());
        }}
      />
    </div>
  );
};

export default Sidebar;
