/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import FilterIcon from '@mui/icons-material/FilterList';
import { MenuItem } from '@mui/material';
import SortIcon from '@mui/icons-material/UnfoldMore';
import Profile from '../../../components/profile/profile';
import Search from '../../../components/filter/Search';
import Table from '../../../components/table/index';
import TableStatus from '../../../components/table/status';
import ButtonDetail from '../../../components/table/button-detail';
import FilterModal from '../../../components/modal/FilterModal';
import { exportPenerimaan, getPenerimaanDanaInfaq } from '../../../services/infaq';
import '../../../assets/styles/infaq.scss';
import excelIcon from '../../../assets/images/icons/excelIcon.svg';

function Index() {
  const styledButton = {
    textTransform: 'capitalize',
    color: '#4F5867',
    background: '#fff',
    border: '1px solid #e4e4e4',
    borderRadius: '8px',
    boxShadow: 'none',
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(2);
  const [totalData, setTotalData] = useState(0);
  const [sort, setSort] = useState('programName.asc');
  const [penerimaanDanaInfaq, setPenerimaanDanaInfaq] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paramFilter, setParamFilter] = useState({});
  const [isOnFilter, setIsOnFilter] = useState(false);

  const optionsFilter = [
    { value: 'semua_status', label: 'Semua Status' },
    { value: 'berhasil', label: 'Berhasil' },
    { value: 'menunggu', label: 'Menunggu' },
    { value: 'gagal', label: 'Gagal' },
  ];

  useEffect(() => {
    const handleTable = async () => {
      const dataPenerimaanInfaq = await getPenerimaanDanaInfaq({
        deleted: false,
        draw: 0,
        id: '',
        length: 10,
        page,
        rangeEnd: paramFilter?.endDate ?? '',
        rangeStart: paramFilter?.startDate ?? '',
        searchText: searchKeyword,
        sort: [
          sort,
        ],
        start: 1,
        status: paramFilter?.selectValue ?? '',
        isPatronage: true,
      });
      setPenerimaanDanaInfaq(dataPenerimaanInfaq.data);
      setTotalData(dataPenerimaanInfaq.recordTotal);
      setMaxPage(Math.ceil(dataPenerimaanInfaq.recordTotal / 10));
    };
    handleTable();
  }, [
    searchKeyword,
    sort,
    page,
    paramFilter,
  ]);

  const columns = [
    {
      Header: 'No',
      accessor: 'no',
      Cell: ({ row }) => <div>{row.index + 1}</div>,
    },
    {
      Header: 'Nama Donatur',
      Cell: ({ row }) => {
        const { original } = row;
        const { user } = original;
        if (user) {
          return user.name;
        }
        return 'Anonim';
      },
    },
    {
      Header: 'Tanggal',
      Cell: ({ row }) => {
        const monthNames = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
        const { original } = row;
        const { admissionDateFmt } = original;
        return admissionDateFmt;
        // const splitedDate = admissionDate.split('-');
        // return `${splitedDate[2]} ${monthNames[splitedDate[1] - 1]} ${splitedDate[0]}`;
      },
    },
    {
      Header: 'Nama Patronage',
      Cell: ({ row }) => {
        const { original } = row;
        const { infaqProgram } = original;
        return infaqProgram.programName;
      },
    },
    {
      Header: 'Jumlah',
      Cell: ({ row }) => {
        const { original } = row;
        const { amount } = original;
        const moneyFormatter = new Intl.NumberFormat();
        return `Rp ${moneyFormatter.format(amount).replaceAll(',', '.')}`;
      },
    },
    {
      Header: 'Status',
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;
        const str2 = status.charAt(0).toUpperCase() + status.slice(1);
        return <TableStatus status={str2} color={status === 'berhasil' ? 'primary' : status === 'gagal' ? 'danger' : 'warning'} />;
      },
    },
    {
      Header: ' ',
      Cell: ({ row }) => {
        const { original } = row;
        const { id } = original;
        const { status } = original;
        return (
          <ButtonDetail>
            <MenuItem onClick={() => {
              navigate(`/patronage/penerimaan/detail-penerimaan/${id}`);
            }}
            >
              Detail

            </MenuItem>
            {status === 'berhasil' && (
            <MenuItem onClick={() => {
              navigate(`/patronage/penerimaan/cetak-nota/${id}`);
            }}
            >
              Cetak Nota

            </MenuItem>
            )}
          </ButtonDetail>
        );
      },
    },
  ];

  const handleSort = () => {
    if (sort === 'programName.asc') {
      setSort('programName.desc');
    } else {
      setSort('programName.asc');
    }
  };

  const handleFilter = (selectedOption, secondSelectedOption, startDate, endDate) => {
    let filterStatus;
    let filterStartDate;
    let filterEndDate;

    if (selectedOption && selectedOption.value !== undefined) {
      filterStatus = selectedOption.value;
    }
    const end = new Date(endDate);
    const start = new Date(startDate);

    if (startDate !== null && endDate !== null) {
      const rangeStart = start.toLocaleDateString().split('/');
      const rangeEnd = end.toLocaleDateString().split('/');
      filterStartDate = `${rangeStart[2]}/${rangeStart[0]}/${rangeStart[1]}`;
      filterEndDate = `${rangeEnd[2]}/${rangeEnd[0]}/${rangeEnd[1]}`;
    }

    setParamFilter({
      selectValue: filterStatus,
      startDate: filterStartDate,
      endDate: filterEndDate,
    });
    setShowModalFilter(false);
    setIsOnFilter(true);
    if (!filterStatus && !filterStartDate && !filterEndDate) {
      setIsOnFilter(false);
    }
  };

  const handleFilterReset = () => {
    setParamFilter({
      selectValue: '',
      startDate: null,
      endDate: null,
    });
    setShowModalFilter(false);
    setIsOnFilter(false);
  };
  const handleExport = async () => {
    const dataPenerimaanInfaq = {
      deleted: false,
      draw: 0,
      id: '',
      length: 10,
      page,
      rangeEnd: paramFilter?.endDate ?? '',
      rangeStart: paramFilter?.startDate ?? '',
      searchText: searchKeyword,
      sort: [
        sort,
      ],
      start: 1,
      status: paramFilter?.selectValue ?? '',
      isPatronage: true,
    };

    const formData = new FormData();

    formData.append('deleted', dataPenerimaanInfaq.deleted);
    formData.append('draw', dataPenerimaanInfaq.draw);
    formData.append('id', dataPenerimaanInfaq.id);
    formData.append('length', dataPenerimaanInfaq.length);
    formData.append('page', dataPenerimaanInfaq.page);
    formData.append('rangeEnd', dataPenerimaanInfaq.rangeEnd);
    formData.append('rangeStart', dataPenerimaanInfaq.rangeStart);
    formData.append('searchText', dataPenerimaanInfaq.searchText);
    formData.append('sort', dataPenerimaanInfaq.sort);
    formData.append('start', dataPenerimaanInfaq.start);
    formData.append('status', dataPenerimaanInfaq.status);
    const response = await exportPenerimaan(formData);
    return response;
  };
  return (
    <div className="content-container-side">
      <div className="d-flex flex-wrap">
        <p className="content-title mt-3">Patronage</p>
        <div className="ms-auto">
          <div className="d-flex flex-wrap justify-content-end align-items-center">
            <Profile label="Ridwan Fajar Widodo" />
          </div>
        </div>
      </div>
      <Card className="card-datatable">
        <div className="d-flex flex-wrap align-items-center">
          <p className="card-datatable-title">Database Penerimaan Dana</p>
          <div className="ms-auto">
            <div className="d-flex flex-wrap justify-content-end align-items-center">
              <Search
                name="search"
                type="text"
                placeholder="Masukkan kata kunci"
                style={{ marginRight: 10 }}
                mt="3 mt-sm-0"
                value={searchKeyword}
                onChange={(event) => setSearchKeyword(event.target.value)}
              />
              <div className="mt-3 mt-sm-0 me-2">
                {isOnFilter ? (
                  <Badge variant="dot" color="warning">
                    <Button
                      onClick={() => setShowModalFilter(true)}
                      variant="contained"
                      aria-label="filter"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      size="medium"
                      sx={{
                        border: '1px solid #2EC4B6 !important',
                      }}
                      style={styledButton}
                      endIcon={<FilterIcon />}
                    >
                      Filter
                    </Button>
                  </Badge>
                ) : (
                  <Button
                    onClick={() => setShowModalFilter(true)}
                    variant="contained"
                    aria-label="filter"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    size="medium"
                    style={styledButton}
                    endIcon={<FilterIcon />}
                  >
                    Filter
                  </Button>
                )}
              </div>
              <Button
                variant="contained"
                onClick={handleSort}
                aria-label="filter"
                aria-controls="long-menu"
                aria-haspopup="true"
                style={styledButton}
                size="medium"
                endIcon={<SortIcon />}
              >
                Sort
              </Button>
              <Button
                onClick={() => handleExport()}
                variant="outlined"
                aria-label="add"
                size="medium"
                style={{
                  textAlign: 'center',
                  padding: '0.5em 1em',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.5rem',
                  color: '#2EC4B6',
                }}
                className="ms-2 mt-3 mt-sm-0 export-btn"
              >
                <i className="me-1">
                  <img src={excelIcon} alt="excel export" />
                </i>
                Export
              </Button>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          data={penerimaanDanaInfaq}
          pagination={{
            page,
            totalData,
            maxPage,
            toggle: setPage,
          }}
        />
      </Card>
      <FilterModal
        show={showModalFilter}
        onHide={() => setShowModalFilter(false)}
        select
        titleSelect="Status"
        placeholderSelect="Pilih Status"
        confirmTitle="Terapkan"
        cancelTitle="Batal"
        withDateRange
        selectOptions={optionsFilter}
        paramFilter={paramFilter}
        handleFilter={handleFilter}
        handleReset={handleFilterReset}
        toggle={() => setShowModalFilter(false)}
        confirm={() => {
        }}
      />
    </div>
  );
}

export default Index;
